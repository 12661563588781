@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato&family=Playfair:ital,wght@0,400;0,500;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;0,500;1,100;1,300&display=swap");
/* @layer base {
  .output-description {
    list-style: disc;
  }
} */
@layer components {
  body {
    letter-spacing: 0.75px;
    /* font-family: "Agdasima", sans-serif;
    font-family: "Lato", sans-serif;
    font-family: "Roboto", sans-serif; */
    font-family: "Kanit", sans-serif;

    font-weight: 400;
    @apply bg-back;
    letter-spacing: 0.001px;
    font-size: medium;
  }
  body.dark {
    @apply dark-bg-back dark-text;
  }
  .custom-table-head {
    @apply bg-black;
  }
  *::-webkit-scrollbar {
    @apply hidden;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input,
  textarea {
    @apply dark:dark-bg-back bg-back;
  }

  .container {
    @apply w-full md:w-3/4 mx-auto px-2;
  }
  .nav-item {
    @apply flex items-baseline justify-between duration-1000 before:duration-300  hover:text-orange-500  transition-all relative  mx-auto dark:dark-text before:absolute before:top-full before:left-0 before:h-0 md:before:h-0.5 before:w-full before:scale-x-[1.2] before:opacity-0 hover:before:opacity-100 hover:before:scale-x-[0.30] before:bg-orange-500 before:transition-all before:origin-center;
    transition: width 1s ease-in-out;
  }
  .nav-item.nav-item-hover {
    @apply hover:before:w-full;
  }
  .nav-item.active {
    @apply text-orange-500 before:absolute before:top-full before:left-0 before:h-0 md:before:h-0.5 before:w-full before:bg-orange-500 before:transition-all;
  }
  .md-navbar {
    @apply z-40 bg-front dark:dark-bg-front dark:text-black border-t-2 md:border-t-0 fixed left-0 bottom-0 md:border-b-2 md:border-b-gray-300 shadow-lg  text-sm md:sticky md:top-0  md:left-0;
  }
  .md-navbar .nav-item {
    @apply flex-col items-center md:flex-row;
  }
  .dark-text {
    @apply text-gray-200;
  }
  .dark-bg-front {
    @apply bg-zinc-800;
    /* @apply bg-gray-900; */
  }
  .dark-bg-back {
    /* @apply bg-gray-800; */
    @apply bg-zinc-900;
  }
  .bg-front {
    @apply bg-white;
  }
  .bg-back {
    @apply bg-gray-100;
  }
  .site-btn {
    @apply rounded-lg bg-back dark:dark-bg-back  ring-1 ring-orange-500 click-effect transition-all duration-300  ease-in-out hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-600 hover:text-white dark:dark-text dark:dark-bg-front hover:duration-300 disabled:bg-gray-500 disabled:ring-0 disabled:cursor-not-allowed disabled:hover:bg-none;
  }
  .box {
    @apply shadow-xl  rounded-lg bg-front dark:dark-bg-front;
  }
  .box-ring {
    @apply border border-orange-500;
  }
  .banner-height {
    @apply h-auto;
  }
  .banner-container {
    @apply w-full h-auto;
  }
  .click-effect {
    @apply active:scale-95 disabled:active:scale-100;
  }
  .toast {
    @apply border-2 border-orange-300;
  }
  /* .custom-img {
    max-width: 100%;
    max-height: 60%;
  } */
  option,
  select {
    @apply dark:dark-text dark:dark-bg-back;
  }

  .text-brand {
    @apply text-orange-500;
  }
  /* support floating style  */
  .floating-support {
    @apply hover:bg-white  my-2   md:text-4xl text-2xl rounded-full p-2 items-center flex justify-center duration-150;
  }

  /* some other classes */
  .no-scrollbar::-webkit-scrollbar {
    @apply hidden;
    scrollbar-width: 0;
  }
  /*order details table classes  */
  table.order-details {
    @apply w-full rounded-sm;
  }
  table.order-details tr {
    @apply border-b border-b-gray-200 dark:border-b-gray-700 last:border-b-0;
  }

  table.order-details tr td {
    @apply first:font-semibold last:text-right py-3;
  }

  /*Loading Screen classes  */
  .loading {
    background-color: rgba(25, 25, 25, 0.73);
    z-index: 100;
  }
  /*Data table css*/
  .faketable-wrapper {
    @apply rounded-lg shadow-xl my-3 mx-2 bg-front dark:dark-bg-front  font-light;
  }
  .faketable-header {
    @apply bg-front dark:dark-bg-front;
  }

  .faketable-thead-th {
    @apply text-left py-3 dark:bg-gray-700 bg-gray-300 dark:dark-text font-bold;
  }
  .faketable-tbody-td {
    @apply py-3;
  }
  .faketable-tbody-tr {
    @apply hover:bg-gray-200 dark:hover:bg-zinc-950 duration-100 even:hover:bg-gray-200 even:dark:hover:bg-zinc-950;
  }
  .faketable-tbody-tr {
    @apply even:bg-gray-100 even:dark:bg-zinc-900;
  }
  .faketable-tbody-td,
  .faketable-thead-th {
    @apply px-3 border-b dark:border-b-gray-600;
  }
  .faketable-header-filter-section {
    @apply flex flex-wrap justify-end;
  }

  /* quillbar color set properties  */
  .description-output {
    @apply prose text-gray-900 dark:dark-text max-w-full;
    transition: max-height 0.5 ease-in-out;
  }
  .description-output h1 {
    @apply text-4xl;
  }
  .description-output h1,
  .description-output h2,
  .description-output h3,
  .description-output h4,
  .description-output h5,
  .description-output h6,
  .description-output p,
  .description-output strong {
    @apply text-gray-900 dark:dark-text m-0;
  }
  .jodit-status-bar {
    @apply hidden;
  }
  .jodit-react-container {
    @apply h-full;
  }
}
