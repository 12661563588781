select,
input,
textarea {
  background-color: inherit;
}
option {
  color: black;
}
/* option:checked {
  background-color: rgb(150, 150, 150) !important;
  color: black;
} */

.sorticon {
  display: inline;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.faketable {
  width: 100%;
}
.faketable-thead-th:hover .sorticon {
  opacity: 1;
}
.rotate180 {
  transform: rotate(180deg);
}
.pointer-cursor {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.faketable-header-Title-section {
  padding: 10px;
}
.table-fixed {
  table-layout: fixed;
}
/* pagination section 
 */

.faketable-pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.faketable-pagination-wrapper {
  padding: 15px 10px;
  border-top: 1px solid gray;
  
}
.faketable-pagination {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.faketable-pagination-btn-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.faketable-pagination-btn-group select {
  padding: 5px 10px;
  margin: 0 5px;
  outline: 1px solid gray;
  border-radius: 2px;
}
.faketable-pagination-btn {
  padding: 5px;
  border-radius: 50%;
  margin: 1px 5px;
  width: 1.8rem;
  height: 1.8rem;
  background-color: rgba(128, 128, 128, 0.351);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.faketable-pagination-btn:disabled {
  background-color: rgba(255, 255, 255, 0);
}

thead {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}
.faketable-wrapper {
  transform: scale(1);
}
.faketable-wrapper-table {
  width: 100%;
  position: relative;
  overflow: visible;
  padding-bottom: 150px;
}
.faketable-wrapper-table.scrollable {
  overflow: auto;
}
