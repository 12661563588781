.jodit-react-container {
  @apply h-full;
}

#custom-mouse {
  position: relative;
}
#custom-mouse::after {
  position: fixed;
  content: "";
  width: 0%;
  height: 0%;
  z-index: 99998;
  border-radius: 50%;
  box-shadow: 7px 7px 150px 30px #ff8c007d;
  @apply hidden md:block;
}
